import React from "react";
import loader from "../../assets/images/loader.svg";

const SmallLoader = () => {
	return (
		<div className="login__loading2">
			<img src={loader} alt="" />
		</div>
	);
};

export default SmallLoader;
